import styled from "styled-components";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import React, { useState } from 'react';
import gancaran from "../../soal/gancaran";
import tembang from "../../soal/tembang";
import { Link } from "react-router-dom";
import axios from "axios";

const styles = {
    colContainer: {
        height: "calc(100vh - 200px)",
        background: 'radial-gradient(circle, rgba(247,232,175,1) 22%, rgba(222,209,158,1) 50%)', border: "14px groove #D7A56D", borderRadius: "20px",
        position: "relative"
    },
    colContainerTop: {
        // height: '80vh',
        background: 'radial-gradient(circle, rgba(247,232,175,1) 22%, rgba(222,209,158,1) 50%)', border: "14px groove #D7A56D", borderRadius: "20px",
        position: "relative",
        marginBottom: "20px"
    },
    colTitle: {
        background: "#A7E24D",
        border: "5px solid #3F8D44",
        borderRadius: "20px",
        width: "100px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #3F8D44, 1px -1px 0 #3F8D44, -1px 1px 0 #3F8D44, 1px 1px 0 #3F8D44",
        fontFamily: "'Comfortaa'"
    },
    buttonMenu: {
        background: "rgb(53,131,22)",
        background: "linear-gradient(0deg, rgba(53,131,22,1) 25%, rgba(108,171,67,1) 67%)",
        border: "5px solid #349c0b",
        borderRadius: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #349c0b, 1px -1px 0 #349c0b, -1px 1px 0 #349c0b, 1px 1px 0 #349c0b",
        fontFamily: "'Comfortaa'",
        width: "40px",
        textDecoration: "none",
        display: "inline-block",
        margin: "2px",
        marginBottom: "5px"
    },
    bottomButton: {
        background: "rgb(236,100,85)",
        background: "linear-gradient(180deg, rgba(236,100,85,1) 25%, rgba(222,53,41,1) 67%)",
        border: "5px solid #9c1507",
        borderRadius: "20px",
        width: "120px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #9c1507, 1px -1px 0 #9c1507, -1px 1px 0 #9c1507, 1px 1px 0 #9c1507",
        fontFamily: "'Comfortaa'",
        position: "absolute",
        bottom: "-20px",
        right: "30px",
        cursor: "pointer"
    },
    timer: {
        fontFamily: "'Orbitron', sans-serif",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100px",
        textAlign: "center",
        background: "#B45B20",
        marginBottom: "20px",
        color: '#FAFAFA',
        fontSize: "20px"
    }
}

function Menu() {
    const [modal, setModal] = useState(false);
    const [gancaran, setGancaran] = useState([]);
    const [tembang, setTembang] = useState([]);

    const toggle = () => setModal(!modal);

    React.useEffect(() => {
        async function fetchList() {
            const list = await axios.get('/soal');
            setGancaran(list.data.gancaran || []);
            setTembang(list.data.tembang || []);
        }
        fetchList();
    }, [])

    return (
        <section>
            <Container style={{
                // marginTop: "50px",
                maxWidth: "420px"
            }}>
                <div style={{ height: "50px" }}></div>
                <div style={styles.colContainerTop}>
                    <div style={{ margin: "10px" }}>
                        Pilih salah satu jenis soal di bawah ini.
                    </div>
                </div>
                <Row>
                    <Col sm="6">
                        <div style={styles.colContainer}>
                            <div style={styles.colTitle}>
                                Remaja
                            </div>
                            <div style={{ overflow: "auto", margin: "10px", position: "absolute", height: "90%", width: "calc(100% - 20px)" }}>
                                {
                                    gancaran.map((d, idx) => {
                                        return (
                                            <Link style={styles.buttonMenu} to="/latihan" state={{
                                                type: "gancaran",
                                                index: d.id
                                            }}>
                                                {idx + 1}
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div style={styles.colContainer}>
                            <div style={styles.colTitle}>
                                Dewasa
                            </div>
                            <div style={{ overflow: "auto", margin: "10px", position: "absolute", height: "90%", width: "calc(100% - 20px)" }}>
                                {
                                    tembang.map((d, idx) => {
                                        return (
                                            <Link style={styles.buttonMenu} to="/latihan" state={{
                                                type: "tembang",
                                                index: d.id
                                            }}>
                                                {idx + 1}
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Menu;
