import styled from "styled-components";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import React, { useState } from 'react';
import { useEffect } from "react";
import gancaran from "../../soal/gancaran";
import tembang from "../../soal/tembang";
import { Link, useNavigation } from "react-router-dom";
import { diffWords } from "../../lib/diff";
import ModalAuth from "../../components/molecules/Modal/ModalAuth";
import axios from "axios";

const styles = {
    colContainer: {
        height: 'calc(100vh - 100px)',
        background: 'radial-gradient(circle, rgba(247,232,175,1) 22%, rgba(222,209,158,1) 50%)', border: "14px groove #D7A56D", borderRadius: "20px",
        position: "relative"
    },
    colTitle: {
        background: "#A7E24D",
        border: "5px solid #3F8D44",
        borderRadius: "20px",
        width: "100px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #3F8D44, 1px -1px 0 #3F8D44, -1px 1px 0 #3F8D44, 1px 1px 0 #3F8D44",
        fontFamily: "'Comfortaa'"
    },
    bottomButton: {
        background: "rgb(236,100,85)",
        background: "linear-gradient(180deg, rgba(236,100,85,1) 25%, rgba(222,53,41,1) 67%)",
        border: "5px solid #9c1507",
        borderRadius: "20px",
        width: "120px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #9c1507, 1px -1px 0 #9c1507, -1px 1px 0 #9c1507, 1px 1px 0 #9c1507",
        fontFamily: "'Comfortaa'",
        position: "absolute",
        bottom: "-20px",
        right: "30px",
        cursor: "pointer"
    },
    timer: {
        fontFamily: "'Orbitron', sans-serif",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100px",
        textAlign: "center",
        background: "#B45B20",
        marginBottom: "20px",
        color: '#FAFAFA',
        fontSize: "20px"
    },
    backButton: {
        position: "absolute",
        left: "50px",
        top: "10px"
    }
}

function Converter({ index }) {
    const [modal, setModal] = useState(false);
    const [modalSkor, setModalSkor] = useState(false);
    const [modalAuth, setModalAuth] = useState(false);
    const [soal, setSoal] = useState("");
    const [kunci, setKunci] = useState("");
    const [jawaban, setJawaban] = useState("");
    const [skor, setSkor] = useState(0);
    const [salah, setSalah] = useState(0);
    const [result, setResult] = useState([]);
    const [id, setId] = useState(index);
    const [email, setEmail] = useState('');
    const [random, setRandom] = useState('');

    function randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const cekSkor = async () => {
        async function cekPremium() {
            try {
                const tokenStr = localStorage.getItem('segajabung_token');
                const resKunci = await axios.post('/api/kunci', {
                    soal: id
                }, {
                    headers: { "Authorization": `Bearer ${tokenStr}` }
                });
                const isPremium = resKunci.data.premium || false;
                const kunciJawaban = resKunci.data.kunci || null;
                if (isPremium && resKunci.data.user.premium == '0') {
                    setModal(true);
                    setEmail(resKunci.data.user.email);
                    return false;
                }
                setKunci(kunciJawaban.replace("\r\n", "\n"));
                setRandom(randomString(10));
                return true;
            } catch (error) {
                setModalAuth(true);
                return false;
            }
        }
        const cek = await cekPremium();
        if (!cek) {
            return;
        }
    }

    const toggle = () => setModal(!modal);
    const toggleSkor = () => setModalSkor(!modalSkor);
    const toggleAuth = () => setModalAuth(!modalAuth);

    useEffect(() => {
        async function fetchSoal() {
            if (!id) {
                setId(localStorage.getItem('segajabung-id-soal') || 1);
            }
            const soal = await axios.get('/soal/show', {
                params: {
                    id: id
                }
            });
            localStorage.setItem('segajabung-id-soal', soal.data.id);
            setSoal(soal.data.soal);
        }
        fetchSoal();
    }, [])

    useEffect(() => {
        if (!kunci) {
            return;
        }
        const rep = {
            "꧈ ": "꧈",
            "꧉ ": "꧉",
            "꧈꧃": "꧄",
            "꧈꧄": "꧄",
            "꧈꧅": "꧄",
            "꧉꧃": "꧄",
            "꧉꧄": "꧄",
            "꧉꧅": "꧄"
        };
        var jawaban2 = jawaban;
        for (const [key, value] of Object.entries(rep)) {
            jawaban2 = jawaban2.replaceAll(key, value);
        }
        function countDiff(k, j) {
            return diffWords(k, j, {
                comparator: (a, b) => {
                    if (a === b) {
                        return true;
                    }
                    const arrEqual = {
                        "ꦧ꧀ꦕ": "ꦧ꧀ꦖ",
                        "ꦏ": "ꦑ",
                        "ꦒ": "ꦓ",
                        "ꦠ": "ꦡ",
                        "ꦤ": "ꦟ",
                        "ꦥ": "ꦦ",
                        "ꦧ": "ꦨ",
                        "ꦱ": "ꦯ",
                        "ꦲ": "ꦄ",
                        "ꦲꦶ": "ꦆ",
                        "ꦲꦸ": "ꦈ",
                        "ꦲꦺ": "ꦌ",
                        "ꦲꦺꦴ": "ꦎ",
                        "꧀ꦏ": "꧀ꦑ",
                        "꧀ꦒ": "꧀ꦓ",
                        "꧀ꦠ": "꧀ꦡ",
                        "꧀ꦤ": "꧀ꦟ",
                        "꧀ꦥ": "꧀ꦦ",
                        "꧀ꦧ": "꧀ꦨ",
                        "꧀ꦱ": "꧀ꦯ",
                        "꧀ꦲ": "꧀ꦄ",
                        "꧀ꦲꦶ": "꧀ꦆ",
                        "꧀ꦲꦸ": "꧀ꦈ",
                        "꧀ꦲꦺ": "꧀ꦌ",
                        "꧀ꦲꦺꦴ": "꧀ꦎ",
                        "꧃": "꧄",
                        "꧅": "꧄",
                        "​": " ",
                        "‍": " "
                    };
                    for (const [key, value] of Object.entries(arrEqual)) {
                        const c = a.replace(key, value);
                        if (c === b) return true;
                    }
                    return false;
                }
            });
        }
        const diff = countDiff(kunci, jawaban2);
        console.log(diff);
        setResult(diff);
        let jmlKarakter = 0;
        let cRemoved = 0;
        let cAdded = 0;
        let jmlPerbedaan = 0;
        diff.map((val, idx) => {
            if (!val.hasOwnProperty("added")) {
                jmlKarakter += val.count;
                jmlPerbedaan += Math.max(cRemoved, cAdded);
                cRemoved = 0;
                cAdded = 0;
                return;
            }
            if (val.removed) {
                cRemoved = val.count;
                jmlKarakter += val.count;
                if (idx == (diff.length - 1)) {
                    jmlPerbedaan += cRemoved;
                }
            }
            if (val.added) {
                cAdded = val.count;
                if (idx == (diff.length - 1)) {
                    jmlPerbedaan += Math.max(cRemoved, cAdded);
                }
            }

        })
        setSkor(((jmlKarakter - jmlPerbedaan) / jmlKarakter * 100).toFixed(2));
        setSalah(jmlPerbedaan);
        setModalSkor(true);
    }, [kunci, random])

    return (
        <section>
            <Container>
                <div style={styles.timer}>
                    <Timer active duration={null}>
                        <Timecode />
                    </Timer>
                </div>
                <div style={styles.backButton}>
                    <Link to="/">
                        <img src="./assets/images/back.png" width="40" />
                    </Link>
                </div>
                <Row>
                    <Col sm="6">
                        <div style={styles.colContainer}>
                            <div style={styles.colTitle}>
                                Soal
                            </div>
                            <div style={{ overflow: "auto", margin: "10px", position: "absolute", height: "90%" }}>
                                {soal.split("\n").map(function (item) {
                                    return (
                                        <>
                                            {item}
                                            < br />
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div style={styles.colContainer}>
                            <div style={styles.colTitle}>
                                Jawaban
                            </div>
                            <Input
                                style={{
                                    overflow: "auto", left: "0", marginTop: "10px", position: "absolute", height: "90%",
                                    background: "transparent",
                                    fontFamily: "'nyk Ngayogyan Jejeg'",
                                    lineBreak: "anywhere",
                                    lineHeight: "2.3",
                                    border: "none"
                                }}
                                type="textarea"
                                spellCheck={false}
                                onChange={e => { setJawaban(e.target.value) }}
                            />
                            <div style={styles.bottomButton} onClick={cekSkor}>
                                Cek Skor
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModalAuth isOpen={modalAuth} toggle={toggleAuth}></ModalAuth>
            <Modal isOpen={modal} toggle={toggle} className="sj-modal">
                <ModalHeader toggle={toggle}>Selangkah Lagi</ModalHeader>
                <ModalBody>
                    Tinggal selangkah lagi kamu bisa menikmati semua fitur premium Segajabung. Akunmu dengan email <b>{email}</b> sudah terdaftar, silakan hubungi Pengurus Komunitas Segajabung melalui Whatsapp <a href="https://wa.me/6283840460580?text=Halo%2C%20saya%20ingin%20mengaktifkan%20akun%20premium%20segajabung.%0ANama%20%3A%20.....%0AEmail%20%3A%20...." target="_blank">0838-4046-0580 (Arif)</a>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalSkor} toggle={toggleSkor} className="sj-modal">
                <ModalHeader toggle={toggleSkor}>SKOR {skor} (Jumlah Kesalahan: {salah})</ModalHeader>
                <ModalBody style={{ fontFamily: "nyk Ngayogyan Jejeg", lineHeight: "2", lineBreak: "anywhere" }}>
                    <p className="cek-kesalahan">
                        {
                            result.map(val => {
                                val.value = val.value.replace("\n", "\r\n");
                                return (
                                    <>
                                        {!val.added && !val.removed && (val.value).split("\n").map(v => {
                                            return (
                                                <>
                                                    {v}
                                                    {(val.value).split("\n").length > 1 && (<><br /></>)}
                                                </>
                                            )
                                        })
                                        }
                                        {val.added && (val.value).split("\n").map(v => {
                                            return (
                                                <ins>
                                                    {v}
                                                    {(val.value).split("\n").length > 1 && (<><br /></>)}
                                                </ins>
                                            )
                                        })
                                        }

                                        {val.removed && (val.value).split("\n").map(v => {
                                            console.log("lenth", (val.value).split("\n").length);
                                            return (
                                                <del>
                                                    {v}
                                                    {(val.value).split("\n").length > 1 && (<><br /></>)}
                                                </del>
                                            )
                                        })
                                        }
                                    </>
                                )
                            })
                        }
                    </p>
                </ModalBody>
            </Modal>
        </section >
    );
}

export default Converter;
