import { Fragment } from "react";
import Converter from "./Converter";
import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";

function Home(props) {
    const location = useLocation();
    const propsData = location.state;
    const index = propsData.index || 0;
    return (
        <Fragment>
            {/* <Jumbotron /> */}
            <Converter index={index} />
            <Container>
            </Container>
        </Fragment>
    );
}

export default Home;
