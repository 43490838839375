import { Fragment, Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from 'react-router-dom';
import Home from './pages/Home';
import Splash from './pages/Splash';

class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route exact path='/' element={< Splash />}></Route>
                    <Route exact path='/latihan' element={< Home />}></Route>
                    {/* <Route exact path='/about' element={< About />}></Route>
                    <Route exact path='/contact' element={< Contact />}></Route> */}
                </Routes>
            </Router>
        );
    }
}


// function App() {
//     return (
//         <Fragment>
//             <Home />
//         </Fragment>
//     );
// }

export default App;
