import { Fragment } from "react";
import Converter from "../Home/Converter";
import { Container } from "reactstrap";
import Menu from "./Menu";

function Splash() {
    return (
        <Fragment>
            <Menu />
        </Fragment>
    );
}

export default Splash;
